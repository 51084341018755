import { render, staticRenderFns } from "./EntrySinopMain.vue?vue&type=template&id=9c167818&scoped=true&"
import script from "./EntrySinopMain.vue?vue&type=script&lang=js&"
export * from "./EntrySinopMain.vue?vue&type=script&lang=js&"
import style0 from "./EntrySinopMain.vue?vue&type=style&index=0&lang=css&"
import style1 from "./EntrySinopMain.vue?vue&type=style&index=1&id=9c167818&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c167818",
  null
  
)

export default component.exports